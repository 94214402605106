.kanban-container {
    width: 100%;
    /* height: calc(100vh - (73.14px + 65px + 16px)); */
    /* background-color: #F9FBFC; */
    border-radius: 24px;
}

.kanban-panel {
    width: 100%;
    height: calc(100vh - (73.14px + 65px + 58px + 0.5rem));
    overflow-x: overlay;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 16px;
}

.kanban-col {
    display: inline-flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    margin-right: 18px;
    /* background-color: #f6f6f6; */
    border-radius: 8px;
    padding-top: 8px;
}
.kanban-col:nth-child(1) {
    margin-left: 50px;
}

.kanban-col-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
    margin: 8px 0px 8px;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
}

.kanban-col-header > span {
    font-size: 18px;
    font-weight: 600;
    color: #051A29;
}

.kanban-col-header-btn {
    color: var(--general-gray) !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05) !important;
}

.kanban-col-header-btn:hover {
    background-color: #F9FBFC !important;
}

.kanban-col-container {
    height: 100%;
    overflow-y: overlay;
    transition: all .3s ease;
    border-radius: 12px;
    border-style: dashed;
    border-width: 2px;
}